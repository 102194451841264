import * as types from "./mutation_types";
import axios from "axios";
import { CSRF_TOKEN } from "@/common/csrf_token";

export const update = ({ commit, dispatch }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING);
  axios
    .put("/api/assise/partenaire/commerciaux/" + values.id + "/", values.values)
    .then(() => {
      commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_SET_UPDATED, true);
      commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING);
      dispatch(
        "assise/partenaire_update_calendrier/update",
        { id: values.id, values: values.calendriers.values },
        { root: true }
      );
    })
    .catch(() => {
      commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING);
    });
};

export const supprime = ({ commit }, values) => {
  axios.defaults.headers.common = {
    "X-CSRFTOKEN": CSRF_TOKEN,
  };
  commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING);
  axios
    .delete(
      "/api/assise/partenaire/commerciaux/" +
        values.id +
        "/"
    )
    .then(() => {
      commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_SET_UPDATED, true);
      commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING);
    })
    .catch(() => {
      commit(types.ASSISE_PARTENAIRE_COMMERCIAUX_UPDATE_TOGGLE_LOADING);
    });
};
